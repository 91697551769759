import React from 'react';
import {PulseViewModel} from "../../viewModel/PulseView.model";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

import './PulseImageView.scss';

export interface PulseImageViewProps{
    pulse: PulseViewModel
}

export function PulseImageView({pulse}: PulseImageViewProps) {

    return (
        <>
            <div className="ion-padding event-pulse-pulse-image-container" onClick={e => e.stopPropagation()}>
                <PhotoProvider>
                    <PhotoView src={pulse.attachments.s3Location}>
                        <img src={pulse.attachments.s3Location} alt=""/>
                    </PhotoView>
                </PhotoProvider>
            </div>
            <div className={"ion-text-center"}>
                <em>{pulse.message}</em>
            </div>

        </>
    );
}
