import React, {useEffect, useState} from "react";
import {Capacitor} from "@capacitor/core";
import {App} from "@capacitor/app";
import {IonLabel} from "@ionic/react";
import packageJson from "../../../package.json";

export function AppVersion() {
    const [appVersion, setAppVersion] = useState("");


    useEffect(() => {
        async function getAppInfo() {
            let appVersion = `LOCAL.${packageJson.version}`;
            if (Capacitor.isNativePlatform()) {
                const temp = await App.getInfo();
                appVersion = `${packageJson.version}-${temp.version}.${temp.build}`;;
            }
            setAppVersion(appVersion);
        }

        getAppInfo();
    }, []);

    return <IonLabel>
        <p>App Version: {appVersion}</p>
    </IonLabel>
}
